export const toLocalDate = (val: Date): string => {
  return val
    .toLocaleString('ru-RU', {
      day: 'numeric',
      month: 'long',
      hour: 'numeric',
      minute: 'numeric',
    })
    .replace(',', '')
}

export const toLocalDateISO = (val: Date, time = true): string => {
  const tzoffset = val.getTimezoneOffset() * 60000
  return new Date(val.getTime() - tzoffset)
    .toISOString()
    .slice(0, time ? -5 : -14)
}

export const toLocalMonth = (val: Date): string => {
  return (
    val.toLocaleString('ru-RU', {
      month: 'long',
    }) +
    ' ' +
    val.getFullYear()
  )
}

export const toLocalFullDate = (val: Date, year = true): string => {
  return (
    val.toLocaleString('ru-RU', {
      day: 'numeric',
      month: 'long',
    }) + (year ? ' ' + val.getFullYear() : '')
  )
}

export const toLocalShortDate = (val: Date): string => {
  return val.toLocaleDateString('ru-RU')
}

export const toLocalTime = (val: Date): string => {
  return val.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })
}
